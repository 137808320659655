//
//
//
//
//
//
//
//

export default {
  methods: {},
  data: () => ({}),
};
