export default function ({ store, app: { $axios } }) {
  $axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response ? error.response.status : null;
      if (status >= 500) {
        store.commit("toast/setToastData", {
          message: "Ein Fehler ist aufgetreten",
          color: "error",
          timeout: 6000,
        });
      } else if (status == 400) {
        store.commit("toast/setToastData", {
          message: error.response.data.error_msg,
          color: "error",
          timeout: 6000,
        });
      } else {
        return Promise.reject(error);
      }
      return Promise.resolve(error.response);
    }
  );
}
