export const Chart2D = () => import('../../components/Chart2D.vue' /* webpackChunkName: "components/chart2-d" */).then(c => wrapFunctional(c.default || c))
export const Chart3D = () => import('../../components/Chart3D.vue' /* webpackChunkName: "components/chart3-d" */).then(c => wrapFunctional(c.default || c))
export const ChartHistory = () => import('../../components/ChartHistory.vue' /* webpackChunkName: "components/chart-history" */).then(c => wrapFunctional(c.default || c))
export const NeuralNetConfig = () => import('../../components/NeuralNetConfig.vue' /* webpackChunkName: "components/neural-net-config" */).then(c => wrapFunctional(c.default || c))
export const TrainingForm = () => import('../../components/TrainingForm.vue' /* webpackChunkName: "components/training-form" */).then(c => wrapFunctional(c.default || c))
export const LayoutAppBar = () => import('../../components/layout/AppBar.vue' /* webpackChunkName: "components/layout-app-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutToast = () => import('../../components/layout/Toast.vue' /* webpackChunkName: "components/layout-toast" */).then(c => wrapFunctional(c.default || c))
export const ResultsOneIndicator = () => import('../../components/results/OneIndicator.vue' /* webpackChunkName: "components/results-one-indicator" */).then(c => wrapFunctional(c.default || c))
export const ResultsStockInfo = () => import('../../components/results/StockInfo.vue' /* webpackChunkName: "components/results-stock-info" */).then(c => wrapFunctional(c.default || c))
export const ResultsTwoIndicators = () => import('../../components/results/TwoIndicators.vue' /* webpackChunkName: "components/results-two-indicators" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
