export const state = () => ({
  toastData: null,
});

export const mutations = {
  setToastData(state, payload) {
    state.toastData = payload;
  },
};

export const actions = {
  setToastData({ commit }, payload) {
    commit("setToastData", payload);
  },
};
