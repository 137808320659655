//
//
//
//
//
//
//

export default {
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  methods: {
    toHome() {
      window.location.href = "/";
    },
  },
  data() {},
};
