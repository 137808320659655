//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  created: function () {
    this.$store.watch(
      (state) => state.toast.toastData,
      () => {
        const message = this.$store.state.toast.toastData.message;
        if (message !== "") {
          this.show = true;
          this.message = this.$store.state.toast.toastData.message;
          this.color = this.$store.state.toast.toastData.color;
          this.timeout = this.$store.state.toast.toastData.timeout;
        }
      }
    );
  },
  data: () => ({
    message: "",
    timeout: 6000,
    show: false,
    color: "primary",
  }),
  methods: {},
};
