//
//
//
//
//
//
//
//
//
//
//
//
//

import Toast from "@/components/layout/Toast.vue";
import AppBar from "@/components/layout/AppBar.vue";

export default {
  components: {
    Toast,
    AppBar,
  },
  data() {
    return {};
  },
};
